.header--index {
  display: block;
  height: auto;
  background: $color-yellow-new;
  z-index: 0;

  @include media-tablet {
    background: none; }

  @include media-desktop-1600 {
    background: $color-yellow-new;
    padding-left: 5em; }

  .header {
    &__main {
      padding: 0 1.25em;

      @include media-tablet {
        display: grid;
        grid-template-rows: 41.5em 1fr;
        height: 52.875em;
        padding: 0; }

      @include media-tablet-h {
        display: grid;
        grid-template-rows: none;
        grid-template-columns: 28em 1fr;
        height: 37.5em; }

      @include media-desktop {
        grid-template-columns: 33em 1fr; }

      @include media-desktop-1440 {
        grid-template-columns: 36.875em 1fr;
        height: 40.625em; }

      @include media-desktop-1600 {
        position: relative;
        grid-template-columns: 26.5em 1fr;
        width: 100em;
        height: 45.625em;
        margin-left: auto; }

      @include media-desktop-1920 {
        width: 105em; }

      &-left {
        position: relative;
        padding-top: 3.8125em;

        @include media-tablet {
          order: 2;
          display: flex;
          flex-direction: column;
          margin-top: -15.375em;
          padding-top: 0;
          padding-bottom: 2.5em;
          padding-left: 2.5em; }

        @include media-tablet-h {
          order: initial;
          margin-top: 0;
          padding-top: 3.5em;
          padding-left: 1.875em; }

        @include media-desktop {
          padding-top: 4em;
          padding-left: 5em; }

        @include media-desktop-1440 {
          padding-top: 5.5em; }

        @include media-desktop-1600 {
          padding-left: 0; }

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          display: none;
          width: 39.3125em;
          height: 31.5em;
          background: url(@public/images/header/main-left-before-tablet.svg) no-repeat;
          z-index: 20;

          @include media-tablet {
            display: block; }

          @include media-tablet-h {
            width: 32.125em;
            height: 40.625em;
            background: url(@public/images/header/main-left-before-tablet-h.svg) no-repeat; }

          @include media-desktop {
            width: 36.5625em;
            background: url(@public/images/header/main-left-before-desktop.svg) no-repeat; }

          @include media-desktop-1440 {
            width: 39.5em;
            height: 45.625em;
            background: url(@public/images/header/main-left-before-desktop-1440.svg) no-repeat; }

          @include media-desktop-1600 {
            left: -5rem; } }

        &-top {
          margin-bottom: 2em;

          @include media-tablet {
            margin-bottom: 1.25em;
            z-index: 50; }

          @include media-tablet-h {
            margin-bottom: 2.5em; } }

        &-date {
          @include fs18;
          margin-bottom: 0.25rem;
          font-weight: 700;
          color: $color-black;

          @include media-tablet {
            @include h24;
            margin-bottom: 0; } }

        &-logo {
          margin-bottom: 0.25rem;

          @include media-tablet {
            margin-bottom: 1.25em; }

          img {
            display: block;
            width: 17.375em;

            @include media-tablet {
              width: 18.6875em;
              max-width: none; }

            @include media-tablet-h {
              width: 23.6875em; }

            @include media-desktop-1440 {
              width: 27.5em; } } }

        &-text {
          @include h36;
          width: 21.625rem;
          text-transform: uppercase;
          color: $color-black;

          @include media-tablet-h {
            @include h48; } }

        &-bottom {
          @include media-tablet {
            width: 24.375em;
            z-index: 50; }

          @include media-tablet-h {
            margin: 0;
            width: 100%; } }

        &-desc {
          @include fs24;
          display: none;
          margin-bottom: 2.5rem;
          color: $color-black;

          @include media-tablet {
            display: block;
            margin-bottom: 1.25rem; }

          @include media-tablet-h {
            width: 25.75rem;
            margin-bottom: 2.5rem; }

          @include media-desktop-1440 {
            width: 27.5rem; } }

        &-reg {
          .btn {
            @include h24;
            width: 17.375rem;
            min-width: auto;
            height: 3.875rem;

            @include media-tablet {
              width: 100%; }

            @include media-tablet-h {
              width: 23.6875rem; }

            @include media-desktop-1440 {
              width: 27.5rem; } } } }

      &-right {
        position: relative;
        height: 10.8125em;

        @include media-tablet {
          order: 1;
          height: 100%; }

        @include media-desktop-1600 {
          background: #fff; } } }

    &-slider {
      position: relative;
      height: 100%;

      @include media-tablet-h {
        width: 100%;
        padding: 0; }

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        right: -15%;
        width: 28.5em;
        height: 8.4375em;
        background: url(@public/images/header/slider-before-mobile.svg) no-repeat;
        z-index: 1;

        @include media-tablet {
          display: none; } }

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        right: -27%;
        width: 28.125em;
        height: 8.25em;
        background: url(@public/images/header/slider-after-mobile.svg) no-repeat;
        z-index: 2;

        @include media-tablet {
          display: none; } }

      &__item {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: none;
        color: #fff;
        z-index: 5;
        opacity: 0;
        animation-name: header;
        animation-duration: 16s;
        animation-iteration-count: infinite;

        @include media-tablet {
          display: block; }

        &:nth-child(1) {
          animation-delay: 0; }

        &:nth-child(2) {
          animation-delay: 4s;

          img {
            object-position: left; } }

        &:nth-child(3) {
          animation-delay: 8s; }

        &:nth-child(4) {
          animation-delay: 12s; }

        // &:nth-child(5)
        //   animation-delay: 16s

        img {
          @include cover-img; } } }

    &-text-slider {
      position: absolute;
      bottom: -11.5625em;
      left: 28.125em;
      display: none;
      width: 28.125em;
      height: 11.25em;

      @include media-tablet {
        display: block; }

      @include media-tablet-h {
        bottom: 0;
        left: 0;
        z-index: 10;
        height: 13.25em; }

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        display: none;
        width: 30em;
        height: 16.0625em;
        background: url(@public/images/header/text-slider-before-tablet.svg) no-repeat;
        z-index: 50;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          left: -17.25em;
          width: 44.75em;
          height: 13.25em;
          background: url(@public/images/header/text-slider-before-tablet-h.svg) no-repeat; }

        @include media-desktop-1440 {
          left: -16.25em; }

        @include media-desktop-1600 {
          left: -11.2rem; } }

      &::after {
        position: absolute;
        content: '';
        bottom: -2.1875em;
        right: -14.5em;
        display: none;
        width: 39.6875em;
        height: 18.75em;
        background: $color-black;
        border-radius: 50%;
        z-index: 10;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; } }

      &__item {
        position: absolute;
        left: 3.6875rem;
        bottom: 0;
        display: none;
        width: 13rem;
        height: 100%;
        align-items: center;
        z-index: 50;
        opacity: 0;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 120%;
        color: #fff;
        animation-name: header;
        animation-duration: 16s;
        animation-iteration-count: infinite;

        @include media-tablet {
          display: flex; }

        @include media-tablet-h {
          left: 2rem; }

        @include media-desktop-1600 {
          left: 6rem; }

        &:nth-child(1) {
          animation-delay: 0; }

        &:nth-child(2) {
          animation-delay: 4s; }

        &:nth-child(3) {
          animation-delay: 8s; }

        &:nth-child(4) {
          animation-delay: 12s; }

        // &:nth-child(5)
 } } } }        //   animation-delay: 16s
