.program {
  position: relative;
  padding-top: 2.625em;
  background-color: $color-black;
  overflow: hidden;
  z-index: 1;

  @include media-tablet {
    padding-top: 5em; }

  @include media-desktop {
    padding-top: 8.3125em; }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -23em;
    width: 53em;
    height: 36.75em;
    background: url(@public/images/program/program-bg-mobile.svg) no-repeat;
    background-size: cover;
    z-index: -1;

    @include media-tablet {
      left: -28em;
      width: 68.4375em;
      height: 31.9375em;
      background: url(@public/images/program/program-bg-tablet.svg) no-repeat;
      background-size: cover; }

    @include media-tablet-h {
      left: -16em;
      width: 72em;
      height: 30em;
      background: url(@public/images/program/program-bg-tablet-h.svg) no-repeat;
      background-size: cover; }

    @include media-desktop {
      left: -10em;
      width: 78em;
      height: 32.3125em; }

    @include media-desktop-1440 {
      left: -6rem; }

    @include media-desktop-1600 {
      left: 0; }

    @include media-desktop-1920 {
      left: -2em; } }

  &__header {
    margin-bottom: 2.5em;

    @include media-tablet {
      margin-bottom: 4em; }

    @include media-tablet-h {
      margin-bottom: 4.25em; } }

  &__title {
    @include h36;
    max-width: 8em;
    width: 100%;
    color: $color-black;

    @include media-tablet {
      @include h48;
      max-width: none;
      color: #fff; } }

  &__slider {
    margin-bottom: 4.625em;

    @include media-tablet {
      margin-bottom: 8.125em; }

    @include media-tablet-h {
      margin-bottom: 5.9375em; }

    @include media-desktop {
      margin-bottom: 8em; }

    &-slide {
      width: 16.25em;

      @include media-tablet {
        width: 34em; } }

    .swiper-slide {
      width: initial;
      min-height: 100%;
      height: initial; }

    .swiper-container {
      overflow: initial; }

    .swiper-pagination {
      bottom: -2.5em !important;

      @include media-tablet {
        bottom: -3.5em !important; }

      @include media-tablet-h {
        bottom: -3.125em !important; }

      @include media-desktop {
        bottpm: 3.625em !important; } }

    .swiper-pagination-bullet {
      width: 0.625em;
      height: 0.625em;
      opacity: 0.33;
      background: $color-black; }

    .swiper-pagination-bullet-active {
      opacity: 1; }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 16.25em;
      min-height: 100%;
      padding: 1.5em;
      border-radius: 1.5em;
      background: #fff;
      box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
      cursor: pointer;

      @include media-tablet {
        padding: 2.5em; }

      &:hover {
        .program__slider-title {
          color: $color-yellow-2; } }

      @include media-tablet {
        width: 34em; } }

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 1.5em; }

    &-date {
      @include fs16;
      color: $color-black;

      @include media-tablet {
        @include fs18; } }

    &-time {
      @include fs16;
      position: relative;
      font-weight: 700;
      color: $color-black;

      @include media-tablet {
        @include h18;
        position: initial;
        top: 0; }

      span {
        @include fs11;
        position: relative;
        top: -0.5em;

        @include media-tablet {
          top: -0.4em; } } }

    hr {
      height: 1.4375em;
      width: 1px;
      margin: 0;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      border: none;
      background-color: $color-black;

      @include media-tablet {
        margin-left: 1em;
        margin-right: 1em; } }

    &-title {
      @include fs18;
      margin-bottom: 0.5rem;
      color: $color-black;
      font-weight: 700;
      transition: all 0.2s linear;

      @include media-tablet {
        @include h24; } }

    &-text {
      @include fs14;
      color: $color-black;

      @include media-tablet {
        @include p18;
        margin-bottom: 1.5rem; } }

    &-avatars {
      display: none;

      @include media-tablet {
        display: flex; }

      &-avatar {
        height: 2.5em;
        width: 2.5em;
        margin-right: 1em;
        border-radius: 100%;
        overflow: hidden; } } } }
