.partners {
  padding: 2.5em 0;
  background-color: $grey-f0;

  @include media-tablet {
    padding: 2.1875em 0; }

  @include media-tablet-h {
    padding: 1.78125em 0; }

  @include media-desktop {
    padding: 2em 0; }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.25em;
    padding: 1.25em;
    background: #fff;
    border-radius: 1.5em;

    @include media-tablet {
      padding: 1.5em; }

    @include media-tablet-h {
      padding: 2em;
      row-gap: 1.875em; }

    @include media-desktop {
      padding: 2.5em;
      row-gap: 2.5em; }

    @include media-desktop-1440 {
      justify-content: space-around; }

    @include media-desktop-1600 {
      padding: 2.5em 4.0625em; } }

  hr {
    width: 100%;
    height: 1px;
    margin: 0;
    background: $grey-89;
    border: none;
    opacity: 0.2; }

  &__group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    row-gap: 1.25em;

    @include media-tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 1em; }

    @include media-tablet-h {
      column-gap: 1.875em; }

    @include media-desktop {
      column-gap: 2.5em; }

    &-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1.25em;

      @include media-tablet {
        flex-direction: row;
        justify-content: space-between;

        hr {
          display: none; } } }

    // &-desktop
    //   display: none

    //   @include media-desktop-1600
    //     display: flex
    //     justify-content: space-around

    // &-mobile
    //   display: flex
    //   flex-direction: column
    //   row-gap: 1.25em

    //   @include media-tablet-h
    //     row-gap: 1.875em

    //   @include media-desktop-1440
    //     justify-content: space-around

    //   @include media-desktop-1600
    //     display: none

    &--org {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .partners__item {
        @include media-tablet-h {
          height: 3.9375em; }

        @include media-desktop {
          height: 4.5em; }

        @include media-desktop-1440 {
          height: 5.125em; } } }

    &--sup {
      .partners__list {
        @include media-tablet-h {
          column-gap: 2.75em; } }

      .partners__item {
        @include media-tablet-h {
          height: 4.375em; }

        @include media-desktop {
          height: 5em; }

        @include media-desktop-1440 {
          height: 6.125em; } } }

    &--support {
      .partners__item {
        @include media-tablet {
          max-width: 5.3125em; }

        @include media-tablet-h {
          max-width: 9.375em;
          height: 4.125em; } } }

    &--sponsor {
      grid-row: 2;

      @include media-tablet {
        grid-row: 1;
        grid-column: 2;
        justify-self: end; } } }

  &__title {
    font-size: 0.875rem;
    color: $grey;
    line-height: 1.0625rem;

    @include media-tablet-h {
      font-size: 1rem;
      line-height: 1.1875rem; } }

  &__list {
    @include lreset;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.75em;

    @include media-tablet {
      column-gap: 0.5em; }

    @include media-tablet-h {
      column-gap: 1.125em; }

    @include media-desktop {
      column-gap: 1.75em; }

    @include media-desktop-1440 {
      column-gap: 3.25em; }

    @include media-desktop-1600 {
      column-gap: 3.4375em; } }

  &__item {
    width: 4.5em;
    height: 3.375em;

    @include media-tablet {
      width: auto; }

    img {
      @include contain-img; } } }
